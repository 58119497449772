/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'mr-theme.scss';

body,
html {
  margin: 0;
}


html,
body {
    height: 100%;
    position: relative;
    width: 100%;
    display: block; // overflow: hidden;
}

* {
    font-family: Arial;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    html {
        font-size: 100%;
    }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
    html {
        font-size: 90%;
    }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
    html {
        font-size: 95%;
    }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    html {
        font-size: 85%;
    }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
    html {
        font-size: 80%;
    }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (max-width: 480px) {
    html {
        font-size: 70%;
    }
}

.mat-list-item .mat-list-item-content .mat-pseudo-checkbox {
    display: none;
}

.mat-selection-list .mat-list-option.mat-list-option .mat-list-item-content-reverse .mat-list-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ccc;
}

.mat-selection-list .mat-list-option.mat-list-option .mat-list-item-content-reverse .mat-list-text i {
    padding-right: 1rem;
}

.mat-selection-list .mat-list-item.mat-list-option[aria-selected="false"] .mat-list-item-content-reverse .mat-list-text {
    color: #ccc !important;
}

.mat-selection-list {
    padding-top: 0 !important;
}

.mat-selection-list .mat-list-item .mat-list-item-content-reverse,
.mat-selection-list .mat-list-option .mat-list-item-content-reverse {
    padding-left: 0;
}

.mat-form-field-appearance-standard .mat-form-field-flex {
    padding-top: 0;
}

.mat-form-field-infix {
    padding: .25em 0;
}

a {
    text-decoration: none;
}

.mat-selection-list .mat-list-option .mat-list-item-content {
    padding-left: 0 !important;
}

app-users,
app-groups,
app-applications {
    // overflow: hidden;
    position: relative;
    display: flex;
    min-height: 100%;
    .frameWrapper {
        overflow: visible;
    }
}

app-user-details,
app-invite-user,
app-application-details,
app-group-details {
    position: relative;
    display: flex;
    background: #fff;
    overflow: hidden; // min-height: calc(100% - 2rem);
    margin-top: 2rem;
    box-shadow: 6px 18px 39px 0 rgba(126, 126, 126, 0.07);
}

app-add-group {
    position: relative;
    background: #fff;
    overflow: hidden;
    display: flex; // justify-content: center;
    margin-top: 2rem; // min-height: calc(100% - 2rem);
    .submitWrapper {
        justify-content: left;
        padding: 0;
    }
    .bottomWrapper {
        min-height: auto;
    }
    .submitWrapper {
        padding: 3rem 0;
    }
}

a mat-icon,
a i {
    color: #000;
}

.myWrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 2rem;
    overflow: hidden;
    flex: 1;
    .titleWrapper {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 1rem;
        position: relative;
        display: flex; // padding-left: 1rem;
        i {
            position: relative;
        }
        .title {
            padding-left: 1rem;
        }
    }
}

.coloredButton {
    border-radius: 15px;
    padding: .5rem 1.8rem;
    box-shadow: none;
    color: #fff;
    background-color: #ee8101;
    border: none;
    display: flex;
    align-items: center;
    font-size: .9rem;
    cursor: pointer;
    outline: none;
    &.disabledButton {
        background-color: #ccc;
        opacity: .5;
        cursor: inherit;
    }
}

.tableWrapper {
    overflow-y: auto;
    position: relative;
    display: block;
    max-height: 100%;
    overflow-x: hidden;
    background: #fff;
    table {
        max-height: 100%;
        width: 100%;
        background-color: #F3F3F8;
        border: 2rem solid #fff;
        border-top: 0 !important;
        box-shadow: none !important;
        td {
            padding: .75rem .5rem .75rem 0;
            overflow-wrap: break-word;
            word-break: break-all;
        }
        tr {
            border-width: 0px !important;
        }
    }
    td.mat-cell:last-child,
    td.mat-footer-cell:last-child,
    th.mat-header-cell:last-child {
        text-align: right;
    }
}

.mat-sort-header-button {
    text-align: left !important;
}

.Invitations,
.Groups,
.Applications {
    background-color: transparent;
    width: 2rem;
    height: 2rem;
    background-size: 1.2rem !important;
    background-position: center !important;
    transition: none;
}

.Invitations {
    background: url(/assets/ico-users.svg) no-repeat;
}

.Groups {
    background: url(/assets/ico-groups.svg) no-repeat;
}

.Applications {
    background: url(/assets/ico-app.svg) no-repeat;
}

.searchIcon {
    background: url(/assets/ico-search.svg) no-repeat;
    background-color: transparent;
    width: 1rem;
    height: 1rem;
    background-size: 1rem !important;
    background-position: center !important;
    transition: none;
    position: relative;
    display: block;
}

.mat-selection-list .mat-list-item.mat-list-option[aria-selected="true"] {
    .mat-list-item-content-reverse .mat-list-text .mat-list-item .mat-list-item-content {
        color: #93127e !important;
    }
    .Customers {
        background: url(/assets/ico-users.1.svg) no-repeat;
    }
    .Groups {
        background: url(/assets/ico-groups.1.svg) no-repeat;
    }
    .Applications {
        background: url(/assets/ico-app.1.svg) no-repeat;
    }
}

td {
    a {
        position: relative;
        display: flex;
        justify-content: flex-end;
        .ediIcon {
            background-color: transparent;
            width: 2rem;
            background: url(/assets/ico-edit.svg) no-repeat;
            cursor: pointer;
            height: 2rem;
            background-size: 1rem !important;
            background-position: center !important;
            transition: none;
            position: relative;
            display: block;
        }
    }
}

.spinnerWrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    z-index: 10000;
    background: rgba(250, 250, 250, .7);
    .mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode="indeterminate"],
    .mat-progress-spinner svg {
        width: 50px !important;
        height: 50px !important;
    }
}

.leftMenu {
    .mat-list-text {
        padding: 0 !important;
    }
    .mat-list-option:hover,
    .mat-list-option.mat-list-item-focus,
    .mat-nav-list .mat-list-item:hover,
    .mat-nav-list .mat-list-item.mat-list-item-focus,
    .mat-list-option:active {
        background: transparent !important;
    }
    .mat-list-item-ripple {
        display: none !important;
    }
}

app-application-details,
app-group-details {
    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: #dedede;
    }
    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: #165787;
        height: 1px;
    }
    .mat-tab-label,
    .mat-tab-link {
        background: #fff;
    }
    .mat-tab-label-active,
    .mat-tab-group.mat-primary .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.cdk-tab-disabled) {
        background: rgba(22, 87, 135, 0.07);
        color: #165787;
        opacity: 1;
    }
    .mat-tab-body-wrapper {
        overflow: inherit;
        width: 100%;
    }
    .mat-tab-body,
    .mat-tab-body-content,
    .adminTab,
    .usersTab,
    .rolesTab {
        // height: 100%;
        width: 100%;
    }
}

.mainWrapper .rightWrapper .mat-tab-group {
    width: 100%;
    word-break: break-all;
}

.bottomWrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    flex: 1;
}

.mat-expansion-panel[_ngcontent-c7]:not(.mat-expanded):not([aria-disabled="true"]) .mat-expansion-panel-header[_ngcontent-c7]:hover,
.mat-expansion-panel-header.mat-expanded[_ngcontent-c7]:focus,
.mat-expansion-panel-header.mat-expanded[_ngcontent-c7]:hover,
.mat-expansion-panel-header:hover {
    box-shadow: inherit !important;
    height: 48px !important;
    font-size: unset !important;
}

.mat-expansion-panel-header.mat-expanded[_ngcontent-c7]:hover .mat-expansion-indicator,
.mat-expansion-panel-header.mat-expanded[_ngcontent-c7] .mat-expansion-indicator,
.mat-expansion-panel-header:hover .mat-expansion-indicator,
.mat-expansion-panel-header .mat-expansion-indicator,
.mat-expansion-panel[_ngcontent-c7]:not(.mat-expanded):not([aria-disabled="true"]) .mat-expansion-panel-header[_ngcontent-c7]:hover .mat-expansion-indicator,
.mat-expansion-panel[_ngcontent-c7]:not(.mat-expanded):not([aria-disabled="false"]) .mat-expansion-panel-header[_ngcontent-c7]:hover .mat-expansion-indicator,
.mat-expansion-indicator {
    font-size: 1rem !important;
}

.frameWrapper {
    overflow: hidden;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    .mat-header-cell {
        background: #FFF
    }
    tr:nth-child(even) {
        background: #FFF
    }
    .noResults {
        background: #fff;
        padding: 1rem;
        text-align: center;
        position: relative;
        span {
            color: blue;
        }
    }
    .searchWrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mat-form-field {
            width: 30vw;
        }
    }
}

.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:hover,
.mat-expansion-panel-header.mat-expanded:focus {
    box-shadow: none !important;
}

.mat-expansion-panel {
    box-shadow: 0 6px 24px -15px rgba(0, 0, 0, 0.33) !important;
}

.mat-expansion-panel-header-title {
    font-size: .9rem;
    padding-bottom: .5rem !important;
    padding-top: .5rem !important;
    display: block;
}

.mat-expansion-indicator {
    padding-bottom: .5rem;
}

.mat-expansion-panel-header {
    height: 48px !important;
}

.submitWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    padding: 1rem;
    flex-shrink: 0;
    margin: 3rem 10vw 3rem 0;
}

.mat-checkbox-inner-container {
    width: 13px !important;
    height: 13px !important;
    margin-right: .5rem !important;
    .mat-checkbox-frame {
        border-width: 1px;
        border-color: #979797;
    }
}

.mat-button:hover .mat-button-focus-overlay,
.mat-stroked-button:hover .mat-button-focus-overlay,
.mat-button-ripple.mat-ripple {
    display: none !important;
}

.mat-tab-label {
    min-width: 10px !important;
    height: 40px !important;
    flex: 1;
}

.mat-form-field-flex {
    position: relative;
}

app-application-details,
app-group-details {
    .basicInput.textarea textarea {
        color: #000;
    }
    .basicInput.textarea.ng-untouched {
        color: #dedede;
    }
    .basicInput.textarea.mat-form-field.mat-form-field-hide-placeholder.mat-focused {
        color: #175788;
    }
    .basicInput.textarea.mat-form-field.ng-tns-c6-1.mat-primary.mat-form-field-type-mat-input.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-hide-placeholder.ng-dirty.ng-invalid.mat-form-field-invalid.ng-touched.mat-focused {
        color: #f44336;
    }
    .mat-form-field-invalid {
        &.basicInput.textarea.ng-untouched,
        &.basicInput.textarea textarea,
        &.basicInput.textarea {
            color: #f44336 !important
        }
    }
    .basicInput.textarea.ng-dirty.ng-invalid.mat-form-field-invalid.ng-touched {
        color: #f44336;
    }
    .basicInput.textarea {
        .mat-form-field-infix {
            width: auto !important;
        }
        .mat-form-field-flex {
            position: relative;
            display: block;
        }
        .mat-form-field-infix::after {
            content: ""; // border: .5px solid rgba(0, 0, 0, .42);
            border-left: 1.5px solid #dedede;
            height: 20px;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        .mat-form-field-infix::before {
            content: ""; // border: .5px solid rgba(0, 0, 0, .42);
            border-right: 1.5px solid #dedede;
            height: 20px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        &.mat-focused {
            &.ng-invalid {
                .mat-form-field-infix::before {
                    content: "";
                    border-right: 2px solid;
                }
            }
            .mat-form-field-infix::before {
                content: "";
                border-right: 2px solid #175788;
                height: 20px;
                position: absolute;
                right: 0;
                bottom: 0;
                transition: cubic-bezier(.25, .8, .25, 1);
                transition-property: border-color, border-width;
                transition-duration: .3s;
                transition-delay: .3s;
            }
        }
        &.mat-focused {
            &.ng-invalid {
                .mat-form-field-infix::after {
                    content: "";
                    border-left: 2px solid;
                }
            }
            .mat-form-field-infix::after {
                content: "";
                border-left: 2px solid #175788;
                height: 20px;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: cubic-bezier(.25, .8, .25, 1);
                transition-property: border-color, border-width;
                transition-duration: .3s;
                transition-delay: .3s;
            }
        }
        &.ng-invalid {
            .mat-form-field-infix::before {
                content: "";
                border-right: 1px solid;
                height: 20px;
                position: absolute;
                right: 0;
                bottom: 0;
                transition: cubic-bezier(.25, .8, .25, 1);
                transition-property: border-color, border-width;
                transition-duration: .3s;
                transition-delay: .1s;
            }
        }
        &.ng-invalid {
            .mat-form-field-infix::after {
                content: "";
                border-left: 1px solid;
                height: 20px;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: cubic-bezier(.25, .8, .25, 1);
                transition-property: border-color, border-width;
                transition-duration: .3s;
                transition-delay: .1s;
            }
        }
        textarea.mat-input-element {
            padding: 0 .2rem !important;
            box-sizing: border-box;
            resize: none !important;
        }
    }
}
.errorMessage{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #ffdddd;
    color: #580f0f;
    margin: 1rem 0;
}
.mat-input-element {
    font-size: .9rem !important;
}

app-applications,
app-users,
app-groups {
    td.mat-footer-cell,
    th.mat-header-cell,
    td.mat-cell {
        border-width: 0px !important;
    }
    th.mat-header-cell:last-child {
        padding-right: 30px !important;
    }
}

.tableWrapper {
    box-shadow: 6px 18px 39px 0 rgba(126, 126, 126, 0.07);
}

.mat-checkbox-ripple {
    display: none !important;
}

.categoryWrapper .mat-chip.mat-standard-chip {
    margin: 0 2rem 0 .5rem !important;
    padding: .5rem 2rem !important;
}

app-invite-user .rightWrapper .mat-form-field-flex {
    padding-left: 1rem !important;
}
app-invite-user .leftWrapper  .errorWrapper{
    margin-top: 2.4rem;
}


app-users,
app-groups,
app-applications {
    td.mat-footer-cell,
    th.mat-header-cell,
    td.mat-cell {
        border-width: 0px !important;
    }
    th.mat-header-cell:last-child {
        padding-right: 30px !important;
    }
    .tableWrapper {
        box-shadow: 6px 18px 39px 0 rgba(126, 126, 126, 0.07);
    }
    th.mat-header-cell.mat-column-name {
        padding: 1.8rem 0 1.8rem 24px !important;
    }
}

app-user-details {
    .disabledField {
        .mat-form-field-underline {
            background-color: #dedede;
        }
    }

    .leftWrapper .errorWrapper{
        margin-bottom: 1.5rem;
    }
}

//css for modal windows 
.mat-dialog-container {
    border-radius: 17px !important;
    box-shadow: 0 18px 74px 0 rgba(0, 0, 0, 0.3) !important;
    background-color: #ffffff;
    .mat-dialog-title {
        text-align: center !important;
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
    .mat-form-field-infix {
        width: 100% !important;
    }
    .mat-form-field {
        width: 100%;
    }
    .mat-dialog-actions {
        justify-content: center;
        margin-bottom: 0rem;
    }
    .mat-dialog-content {
        margin: 2rem 0;
        display: flex;
        justify-content: space-between;
        flex-grow: 0;
        align-items: center;
    }
    .coloredButton,
    .copyButton {
        width: 10vw;
        max-width: 120px;
        text-align: center;
        display: block;
    }
}

.cdk-overlay-pane {
    max-width: 600px !important;
}

.mat-checkbox-checked .mat-checkbox-checkmark {
    display: none !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-image: url(/assets/ico-checked.svg) !important;
    background-repeat: no-repeat;
    background-color: transparent !important;
    background-size: 1rem !important;
    background-position: center !important;
    width: 1rem;
    height: 1rem;
    margin-left: .2rem;
    margin-bottom: 0.1rem;
    top: -.1rem;
}

.assignButton {
    cursor: pointer;
}

.mat-checkbox-inner-container .mat-checkbox-frame {
    border-width: 1px;
    border-color: #979797;
    background-color: transparent;
    border-style: solid;
}

.mat-checkbox {
    font-size: .9rem;
}

.panel-content {
    padding: .2rem 0 .8rem 0 !important;
}

app-redemption-dialog {
    input.mat-input-element {
        opacity: .7;
    }
}

app-form-dialog {
    .mat-dialog-title {
        font-size: 1.1rem !important;
    }
    button {
        margin-left: .5rem;
        margin-right: .5rem;
    }
    .mat-dialog-content div {
        font-size: .9rem;
        text-align: center;
    }
}

.noData {
    text-align: center;
}

.errorWrapper {
    position: relative;
    display: block;
    background-color: #ffdddd;
    color: #580f0f;
    padding: 1rem;
    box-sizing: border-box;
    margin-right: 10vw;

    display: flex;
    justify-content: space-between;
}
i#lowerCloseIcon.material-icons{
    font-size: 1rem;
    color: #3d3c3c;
    object-fit: contain;
    font-weight: bold;
    cursor: pointer;
}

app-group-details .mat-tab-body-content {
    overflow: hidden !important;
}

.leftMenu .mat-selection-list .mat-list-item .mat-list-text>*,
.leftMenu .mat-selection-list .mat-list-option .mat-list-text>* {
    outline: 0 !important;
}

app-invite-user {
    .mat-checkbox-layout {
        margin-top: 2rem;
    }
}

.cdk-overlay-container {
    z-index: 100000 !important;
}

app-application-details .assignedAdminWrapper {
    .mat-form-field-appearance-standard .mat-form-field-flex {
        padding-top: 0;
    }
    h4 {
        padding-bottom: 0;
    }
}

app-application-details {
    .mainWrapper .rightWrapper .assignForm,
    .mainWrapper .rightWrapper .createRole {
        .basicInput {
            margin-bottom: 1rem;
        }
    }
}

#white-select .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow{
    color:white !important;
}

#white-select .mat-select-trigger .mat-select-value {
    color: white !important;
}

#white-select .mat-select-trigger .mat-select-value .mat-select-placeholder {
    color: white !important;
}
